.table-wrapper {
    flex: 4 1;
    display: flex;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    /* background-color: blueviolet; */
    flex-direction: column;
    row-gap: 2vh;
}

.table {
    width: 90%;
    border: 1px solid #D6D6D6;
    position: relative;
}
.rectangle{
    height: 100%;
    left: 20%;
    width: 1.2vw;
    position: absolute;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%);
}
.table-header {

    /* border-radius: 6px 6px 0px 0px; */
    display: grid;
    grid-template-rows: 6.7vh;
    /* grid-template-columns: 20% 30% 25% 25%; */
    grid-template-columns: 20% 30% 20% 30%;

}

.table-header-item {
    /* border: 1px solid #D6D6D6; */
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 5%;
}

.table-header-item>p {
    color: #000;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.2vh;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
