@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap');

.header-wrapper {
    flex: 0.5;
    display: flex;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
}

.header-box {
    width: 90%;
    padding: 15px;
    display: flex;
}

.input-wrapper {
    display: flex;
    border: 1px solid #F1F1F1;
    padding: 1vh 1vw;
    align-items: center;
    gap: 1vw;
    border-radius: 100px; 
    background-color: #FEFDFE;
}

input {
    appearance: none;
    border: none;
    background: none;
    width: auto;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 2vh;
}

input::placeholder {
    color: #B7B7B7;
}

input:focus {
    outline: none;
}
