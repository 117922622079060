.table-line {
    display: grid;
    grid-template-rows: 6.7vh;
    grid-template-columns: 20% 30% 20% 30%;
    border: 1px solid #D6D6D6;
}
.table-cell{
    display: flex;
    padding: 5%;
    align-items: center;
    justify-content: center;
}
.table-cell>p{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 2vh;
}

.table-line:hover {
    background-color: rgb(68, 68, 68, 0.1);
}

a {
    text-decoration: none;
    color: inherit;
  }
