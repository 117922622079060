@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.plan-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5vh;
    padding-bottom: 3%;
}

.sketch-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 10%;
    padding-top: 5%;
}

.image {
    width: 35vw;
}

.cards {
    /* width: 90%; */
    width: 77%;
    display: flex;
    flex-wrap: wrap;
    /* justify-contet: space-between; */

    row-gap: 4.5vh;
    column-gap: 3vw;
}

.box {
    display: flex;
    flex-direction: column;
    row-gap: 3vh;
}

.box>p {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.title{
    padding-left: 11%;
    align-self: flex-start;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}